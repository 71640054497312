/* eslint-disable @next/next/no-img-element */
import type { ErrorProps } from './Error.types'

const Error: React.FunctionComponent<ErrorProps> = ({
  code,
  type,
  message,
}) => {
  return (
    <div className="error">
      <div className="error-text">
        <h1>{code}</h1>
        <h4>{type}</h4>
        <p>{message}</p>
      </div>
      <img src="/images/wap-platform.svg" alt="WAP Platform" />
    </div>
  )
}

export default Error
